jQuery(document).ready(function() {
  var controller = new ScrollMagic.Controller();

  var animateBackgroundWord = Array.prototype.slice.call(
    document.querySelectorAll(".animate-background-word")
  );

  var self = this;

  animateBackgroundWord.forEach(function(self) {
    var text = self.querySelectorAll(".bg-word");

    var sceneOne = new TimelineMax();

    sceneOne.fromTo(text, 0.5, { y: 150 }, { y: -250 });

    var scene1 = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 5000,
      offset: 0
    })
      .setTween(sceneOne)
      .setClassToggle(self, "active")
      .addTo(controller);
  });
});
