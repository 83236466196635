// Load background images
jQuery(".load-bg-img").each(function(i, obj) {
  var large_img = jQuery(this).attr("data-bg-large");
  var small_img = jQuery(this).attr("data-bg-small");

  var item = jQuery(this);

  var bgImg = new Image();
  bgImg.onload = function() {
    item.css("background-image", "url(" + bgImg.src + ")");
    item.addClass("loaded");
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = item.attr("data-bg");
  } else if (jQuery(document).width() > 400) {
    bgImg.src = item.attr("data-bg-small");
  } else {
    bgImg.src = item.attr("data-bg-tiny");
  }
});

// Navigation
jQuery(".dropdown").on("show.bs.dropdown", function() {
  var elem = jQuery(this).find(".dropdown-menu");
  setTimeout(function() {
    elem.addClass("inplace");
  }, 100);
});
jQuery(".dropdown").on("hide.bs.dropdown", function() {
  jQuery(this)
    .find(".dropdown-menu")
    .removeClass("inplace");
});

// Bio popups
jQuery(document).ready(function() {
  jQuery(".modal-trigger").on("click", function() {
    var id = jQuery(this).attr("id");
    console.log(jQuery("#" + id + "-content .mtitle").html());
    //jQuery('#' + id + ' .mtitle').html();
    jQuery(".modal .modal-title").html(
      jQuery("#" + id + "-content .mtitle").html()
    );
    jQuery(".modal .modal-body").html(
      jQuery("#" + id + "-content .mbody").html()
    );

    jQuery(".modal").modal("show");
  });
});

jQuery(document).ready(function() {
  jQuery(".answer.collapse").on("hide.bs.collapse", function() {
    jQuery(this)
      .parent()
      .find("h3")
      .removeClass("showing");
  });

  jQuery(".answer.collapse").on("show.bs.collapse	", function() {
    jQuery(this)
      .parent()
      .find("h3")
      .addClass("showing");
  });

  jQuery(".nav.collapse").on("hide.bs.collapse", function() {
    jQuery(".navbar-container").removeClass("show");
    jQuery(".navbar-toggler").removeClass("show");
    jQuery("html").removeClass("show-nav");
    jQuery("body").removeClass("no-scroll");
    setTimeout(function() {
      jQuery(".navbar-container").removeClass("showing");
      jQuery(".navbar-toggler").removeClass("showing");
      jQuery("html").removeClass("showing-nav");
    }, 100);
  });

  jQuery(".nav.collapse").on("show.bs.collapse	", function() {
    jQuery(".navbar-container").addClass("showing");
    jQuery(".navbar-toggler").addClass("showing");
    jQuery("html").addClass("showing-nav");
    jQuery("body").addClass("no-scroll");

    setTimeout(function() {
      jQuery(".navbar-container").addClass("show");
      jQuery(".navbar-toggler").addClass("show");
      jQuery("html").addClass("show-nav");
    }, 100);
  });

  jQuery(".search-form.collapse").on("show.bs.collapse	", function() {
    setTimeout(function() {
      jQuery(".search-form input.search-field").focus();
    }, 100);
  });
});

// If we have a hero Image
if (jQuery(".page-title").length) {
  var bgImg = new Image();
  bgImg.onload = function() {
    jQuery(".page-title").css("background-image", "url(" + bgImg.src + ")");
    jQuery(".page-title").addClass("inplace");
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = jQuery(".page-title").attr("data-bg");
  } else if (jQuery(document).width() > 400) {
    bgImg.src = jQuery(".page-title").attr("data-bg-small");
  } else {
    bgImg.src = jQuery(".page-title").attr("data-bg-tiny");
  }
}

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        jQuery(
          ".marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p"
        ).each(function() {
          var string = jQuery(this).html();
          string = string.replace(/ ([^ ]*)$/, "&nbsp;$1");
          jQuery(this).html(string);
        });

        var viewport_width = jQuery(document).width();
        var offset = 40;
        if (viewport_width < 1000) {
          offset = 0;
        }

        var myElement = document.querySelector("header");
        // construct an instance of Headroom, passing the element
        var headroom = new Headroom(myElement, {
          offset: offset,
          tolerance: {
            down: 11,
            up: 3
          }
        });
        // initialise
        headroom.init();

        // Secondary navigation
        /*
          var secondary_nav = document.querySelector(".secondary-nav nav");
          // construct an instance of Headroom, passing the element
          var headroom_secondary = new Headroom(secondary_nav, {
            offset: 100,
            tolerance: {
              down: 11,
              up: 3
            }
          });
          // initialise
          headroom_secondary.init();
          */
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function getScrollTop() {
  if (typeof pageYOffset !== "undefined") {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
}

function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}

// State of the header.
var top_stick = true;
var darker_nav = false;

// Update the navigation based on the width of the viewport.
function updateNav() {
  //
  // Update header background color.
  //
  var from_top = jQuery(window).scrollTop();
  if (from_top > 10 && !darker_nav) {
    jQuery(".header").addClass("scrollnav");
    darker_nav = true;
  } else if (from_top <= 10 && darker_nav) {
    jQuery(".header").removeClass("scrollnav");
    darker_nav = false;
  }
}

jQuery(document).ready(function() {
  jQuery("html").addClass("js");

  //windowResizeTasks();

  // Remove 300ms delay when tapping on touch devices.
  jQuery(function() {
    FastClick.attach(document.body);
  });

  updateNav();
  jQuery(window).scroll(jQuery.throttle(65, updateNav));
  jQuery(window).on("debouncedresize", function(event) {
    // Your event handler code goes here.
    updateNav();
  });

  jQuery("a.list-group-item").click(function(e) {
    e.preventDefault();

    event.preventDefault();
    //calculate destination place
    var dest = 0;
    if (
      jQuery(this.hash).offset().top >
      jQuery(document).height() - jQuery(window).height()
    ) {
      dest = jQuery(document).height() - jQuery(window).height();
    } else {
      //dest = jQuery(this.hash).offset().top;
      //dest = jQuery(this.hash).position().top;
      dest = jQuery(this.hash).get(0).offsetTop;

      console.log("Skipping to: " + dest);
    }

    jQuery(".scrollspy").scrollTop(dest);
    jQuery("a.list-group-item").removeClass("active");
    jQuery("a.list-group-item").removeClass("active");
    jQuery(this).addClass("active");

    setTimeout(function() {
      jQuery(this).addClass("active");
    }, 20);
  });
});

function vendorEvents() {
  //
  // Vendor hover
  //
  var doc_width = jQuery(window).width();
  if (doc_width > 1000) {
    jQuery("ul.vendors li").on("mouseenter", function(event) {
      jQuery(this)
        .find(".overlay")
        .addClass("opac");
    });
    jQuery("ul.vendors li").on("mouseleave", function(event) {
      jQuery(this)
        .find(".overlay")
        .removeClass("opac");
    });
  } else {
    jQuery("ul.vendors li").on("click", function(event) {
      if (
        !jQuery(this)
          .find(".overlay")
          .hasClass("opac")
      ) {
        jQuery(this)
          .find(".overlay")
          .addClass("opac");
      } else {
        jQuery(this)
          .find(".overlay")
          .removeClass("opac");
      }
    });
  }
}

//
// Parallax
//

jQuery(document).ready(function() {
  // Vendor nav
  jQuery("nav.vendor-nav li").on("click", function() {
    jQuery("ul.vendors").addClass("loading");

    var id = jQuery(this)
      .find("button")
      .attr("id");

    jQuery("nav.vendor-nav li").removeClass("active");
    jQuery(this).addClass("active");

    jQuery.get("vendors?cat=" + id, function(data) {
      setTimeout(function() {
        jQuery("#vendors-list").html(data);
        vendorEvents();
      }, 5);

      setTimeout(function() {
        jQuery("ul.vendors").removeClass("loading");
      }, 10);
    });
  });

  vendorEvents();
});

/* ================================
 * Map locations
 * ============================= */
jQuery(document).ready(function() {
  jQuery(document).on("facetwp-loaded", function() {
    if ("" == FWP.buildQueryString()) {
      jQuery(".reset-button").addClass("disabled");
      jQuery(".reset-button").attr("disabled", "disabled");
    } else {
      jQuery(".reset-button").removeClass("disabled");
      jQuery(".reset-button").removeAttr("disabled", "disabled");
    }

    if (window.FWP_MAP !== undefined) {
      jQuery(".view-on-map").on("click", function(e) {
        var beverage_id = jQuery(this).attr("data-map-location-id");
        var markers = FWP_MAP.get_post_markers(beverage_id);

        FWP_MAP.infoWindow.setContent(markers[0].content);
        FWP_MAP.infoWindow.setPosition(markers[0].position);
        FWP_MAP.infoWindow.open(FWP_MAP.map, markers[0]);
      });
    }
  });

  jQuery(document).on("facetwp-refresh", function() {
    jQuery(".fwp-elements").addClass("loading");
  });

  jQuery(document).on("facetwp-loaded", function() {
    jQuery(".fwp-elements").removeClass("loading");
    //jQuery('.facetwp-radius-dropdown').niceSelect();
  });
});

jQuery(document).ready(function() {
  jQuery(document).on("facetwp-refresh", function() {
    jQuery(".facetwp-template").addClass("loading");
  });

  jQuery(document).on("facetwp-loaded", function() {
    jQuery(".facetwp-template").removeClass("loading");
  });
});

jQuery(document).ready(function() {
  jQuery(".bs-pop").popover({
    trigger: "click focus hover",
    content: "Testing",
    placement: "top"
  });
});
